<template>
  <nav aria-label="Progress">
    <ol role="list" class="space-y-4 md:flex md:space-y-0 md:space-x-8">
      <li v-for="(step, index) in steps" :key="step" class="md:flex-1">
        <a v-if="isCompleted(index)" tabindex="0" class="group pl-4 py-2 flex flex-col border-l-4 border-primary-600 hover:border-primary-800 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4" @click="setStep(step)" @keydown.space.enter="setStep(step)">
          <span v-if="!textless" class="text-xs text-primary-500 font-semibold tracking-wide uppercase group-hover:text-primary-800">{{ `Item ${index + 1}` }}</span>
          <span class="text-sm font-medium" v-text="textless ? '' : step"></span>
        </a>
        <a v-else-if="isCurrent(index)" tabindex="0" class="pl-4 py-2 flex flex-col border-l-4 border-primary-600 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4" aria-current="step" @click="setStep(step)" @keydown.space.enter="setStep(step)">
          <span v-if="!textless" class="text-xs text-primary-500 font-semibold tracking-wide uppercase">{{ `Item ${index + 1}` }}</span>
          <span class="text-sm font-medium" v-text="textless ? '' : step"></span>
        </a>
        <a v-else tabindex="0" class="group pl-4 py-2 flex flex-col border-l-4 border-gray-200 hover:border-gray-300 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4" @click="setStep(step)" @keydown.space.enter="setStep(step)">
          <span v-if="!textless" class="text-xs text-gray-500 font-semibold tracking-wide uppercase group-hover:text-gray-700">{{ `Item ${index + 1}` }}</span>
          <span class="text-sm font-medium" v-text="textless ? '' : step"></span>
        </a>
      </li>
    </ol>
  </nav>
  <slot></slot>
  <div class="flex justify-between pb-2">
    <div v-if="!buttonless" class="flex flex-row gap-x-2">
      <AButton v-if="stepIndex !== 0" :color="'secondary'" :shade="50" :text-color="'black'" @click="decrementStep">Back</AButton>
      <AButton v-if="stepIndex !== steps.length - 1" @click="incrementStep">Next</AButton>
    </div>
    <div class="flex flex-row gap-x-2">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import useStepper from "./composables/use-stepper";
import {watch} from "vue";
import AButton from "./AButton.vue"

const props = withDefaults(
    defineProps<{
      steps?: string[]
      init_step?: string | number
      textlessThreshold?: number
      stepIndex?: number
      buttonless?: boolean
    }>(),
    {
      steps: () => ['First step', 'Second step', 'Third step'],
      init_step: 0,
      textlessThreshold: 20
    }
)

const emit = defineEmits(['update:stepIndex'])

const {step, stepIndex, setStep, decrementStep, incrementStep, isCompleted, isCurrent, textless} = useStepper(props)

if(typeof props.stepIndex == 'number') {
  watch(() => props.stepIndex, newValue => {
    setStep(props.steps[newValue])
  })
}

watch(stepIndex, (newValue) => {
  emit('update:stepIndex', newValue)
})
</script>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({name: "Stepper"})
</script>


import { ref, computed } from 'vue';

export default function useStepper(props: any) {
  const initializeStepIndex = () => {
    if(typeof props.init_step === 'number') return props.init_step
    if(typeof props.init_step === 'string') return steps.value.indexOf(props.init_step)
    return 0
  }
  const steps = computed(() => props.steps)
  const stepIndex = ref(initializeStepIndex())
  const step = computed(() => steps.value[stepIndex.value])
  const incrementStep = () => stepIndex.value++
  const decrementStep = () => stepIndex.value--
  const setStep = (_step: string) => stepIndex.value = steps.value.indexOf(_step)

  const isCompleted = (index: number): boolean => index < stepIndex.value
  const isCurrent = (index: number): boolean => index === stepIndex.value

  const textless = computed(() => steps.value.length >= props.textlessThreshold)

  return { stepIndex, step, incrementStep, decrementStep, setStep, isCompleted, isCurrent, textless }
}

<template>
  <div class="border border-solid bg-white rounded p-4 my-2" :class="{'flex flex-col': flex}">
    <slot name="header"></slot>
    <div v-if="label || desc" class="mb-4">
      <h1 v-if="label" class="font-bold" v-text="label"></h1>
      <p v-if="desc" class="font-medium text-gray-500" v-text="desc"></p>
    </div>
    <slot></slot>
  </div>
</template>

<script lang="ts" setup>

import {defineProps} from "vue";

const props = defineProps<{
      label?: string
      desc?: string
      flex?: boolean
    }>()
</script>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({name: "BorderedBox"})
</script>